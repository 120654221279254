import React from 'react';
import { string } from 'prop-types';
import { Difficulty } from './Difficulty';
import { TimeNeeded } from './TimeNeeded';

function ProjectGuide({ difficulty, duration }) {
  return (
    <>
      <div className="article-tile__article-list-page__article-listing__items__item__content-requirements">
        <Difficulty difficulty={difficulty} />
        <TimeNeeded timeNeeded={duration} />
      </div>
      <hr className="article-tile__article-list-page__article-listing__items__item__content__hr" />
    </>
  );
}

ProjectGuide.propTypes = {
  difficulty: string.isRequired,
  duration: string.isRequired,
};

export { ProjectGuide };
