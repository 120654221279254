import { HTTP_CODE } from '../../utils/redirector-utils';

export const isNotFound = ({ data }) => {
  const noArticles = data && data.articles && data.articles.totalArticles < 1;
  if ((data && !data?.articles) || noArticles) {
    return {
      shouldRedirect: false,
      isErrorStatus: true,
      httpCode: HTTP_CODE.NOT_FOUND,
      description: 'Page not found',
    };
  }
  return null;
};

export const isALPCanonical = ({ data, path }) => {
  const { canonicalURL } = data?.articles || {};
  if (canonicalURL) {
    if (path !== canonicalURL) {
      return {
        shouldRedirect: true,
        httpCode: HTTP_CODE.PERM_REDIRECT,
        redirectPath: canonicalURL,
        description: 'redirected for canonical'
      };
    }
  }
  return null;
};
