import React from 'react';
import { func, string } from 'prop-types';

const QuickFilterButton = ({ onClick, selectedType, className }) => (
  <button type="button" className={className} onClick={onClick}>
    <span>{selectedType}</span>
    <img
      alt="mobile-caret-icon"
      src="https://assets.thdstatic.com/images/v1/caret-orange.svg"
      height="15"
      width="9"
    />
  </button>
);

QuickFilterButton.propTypes = {
  onClick: func.isRequired,
  selectedType: string.isRequired,
  className: string
};

QuickFilterButton.defaultProps = {
  className: ''
};

export { QuickFilterButton };
