import React, { useState } from 'react';
import {
  arrayOf,
  string,
  bool,
  instanceOf,
  func,
  oneOfType
} from 'prop-types';
import cx from 'classnames';
import { Checkbox } from '@thd-olt-component-react/checkbox';
import styles from './article-listing-filters.module.scss';

const FilterGroup = ({
  header,
  filters,
  loading,
  toggleChecked,
  showFilters,
  checkedFilters,
  className
}) => {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const [expanded, setExpanded] = useState(showFilters);
  const [keywordReq, setKeywordReq] = useState('');

  if (!Array.isArray(filters) || !filters.length) return null;

  let filtersToRender = filters;

  if (header === 'Keyword') {
    const videoKeyword = filtersToRender.filter((item) => item.title === 'Video');
    const otherKeywords = filtersToRender.filter((item) => item.title !== 'Video');

    filtersToRender = videoKeyword.concat(...otherKeywords);
  }

  const renderSearch = filtersToRender.length > 10;
  const renderShowAllButton = filtersToRender.length > 5;
  const showAllText = showAllFilters ? '- See Less' : '+ See All';
  const filterGroupClasses = cx(styles['filter-group-root'], {
    [className]: !!className
  });

  const renderedFilters = filtersToRender.map(({ title, id }, index) => {
    const selected = checkedFilters.includes(id);
    const showFilter = title?.toLowerCase().includes(keywordReq)
      && (index < 5 || showAllFilters || selected);
    const filterClasses = cx({ [styles.hide]: !showFilter });

    return (
      <li key={index} className={filterClasses}>
        <Checkbox
          id={id}
          checked={selected}
          onChange={() => toggleChecked(id)}
          value={id}
          disabled={loading}
        />
        <h3>{title}</h3>
      </li>
    );
  });

  const updateSearch = (event) => {
    setKeywordReq(event.target.value.toLowerCase());
    setShowAllFilters(true);
  };

  const headerClasses = cx({
    [styles['filter-header']]: true
  });

  return (
    <div data-testid="filter-group" className={filterGroupClasses}>
      {!!header
        && (
          <button
            className={styles['filter-header']}
            type="button"
            onClick={() => { setExpanded(!expanded); }}
          >
            <h2>
              {header}
            </h2>
            <img
              className={cx({ [styles['down-arrow']]: !expanded }, styles['caret-icon'])}
              alt="caret-icon"
              src="https://assets.thdstatic.com/images/v1/caret-grey.svg"
              height="15"
              width="9"
            />
          </button>
        )}

      <div className={cx({ [styles.hide]: !expanded })}>
        {renderSearch
          && (
            <input
              onInput={updateSearch}
              type="search"
              placeholder="Search"
              className={styles.search}
            />
          )}
        <ul className={styles['tag-list']}>
          {renderedFilters}
        </ul>
        {renderShowAllButton
          && (
            <button
              type="button"
              className={styles['see-control']}
              onClick={() => { setShowAllFilters(!showAllFilters); }}
            >
              {showAllText}
            </button>
          )}
      </div>
    </div>
  );
};

FilterGroup.propTypes = {
  className: string,
  header: string,
  filters: oneOfType([string, instanceOf(Array)]).isRequired,
  toggleChecked: func.isRequired,
  checkedFilters: arrayOf(string).isRequired,
  showFilters: bool,
  loading: bool
};

FilterGroup.defaultProps = {
  className: '',
  header: '',
  showFilters: true,
  loading: false
};

export { FilterGroup };