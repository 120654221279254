import React from 'react';
import { instanceOf } from 'prop-types';
import classNames from 'classnames/bind';
import styles from '../article-listing-topics.module.scss';

const MobileTopics = ({ topics }) => {

  const mobileContainer = classNames(
    styles['article-listing-topics-mobile']
  );

  return (
    <div
      data-testid="mobile-topics"
      className={mobileContainer}
      data-component="ArticleListingMobileTopics"
    >
      {topics}
    </div>
  );
};

MobileTopics.propTypes = {
  topics: instanceOf(Array).isRequired
};

MobileTopics.displayName = 'MobileTopics';

export { MobileTopics };
