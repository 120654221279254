import React from 'react';
import { string } from 'prop-types';
/* eslint-disable max-len */
const BeginnerDifficulty = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="40"
    width="45"
    viewBox="0 0 75 40"
    className="difficulty"
    data-testid="svg-beginner-difficulty"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFF"
        d="M22.43 36.75v-.52c-.141-8.426 6.672-15.372 15.224-15.52 8.552.148 15.365 7.094 15.224 15.52v.52h13.194v-.52c0-16-12.728-29-28.418-29-15.691 0-28.418 13-28.418 29v.52H22.43z"
      />
      <path
        fill="#F26422"
        d="M22.846 32.61l-13.63-.86a23.59 23.59 0 0 0 0 4.48v.52H22.41v-.52c.004-1.22.15-2.434.436-3.62z"
      />
      <path
        fill="#333"
        d="M37.633 7.75c7.537 0 14.766 2.95 20.095 8.201 5.33 5.251 8.323 12.373 8.323 19.799H53.62c0-8.698-7.157-15.75-15.986-15.75-8.828 0-15.985 7.052-15.985 15.75H9.216c0-15.464 12.723-28 28.417-28zm0-1.75C20.967 6.022 7.462 19.329 7.44 35.75v1.75h15.985v-1.75c0-7.732 6.362-14 14.21-14 7.847 0 14.208 6.268 14.208 14v1.75h15.985v-1.75C67.805 19.329 54.3 6.022 37.633 6z"
      />
      <path
        fill="#333"
        d="M37.633 37.75l.548-3.48L.426 30.19H.162c-.203 0-.213 0 0 .08.086.026.175.043.264.05l.792.16 2.03.4 4.273.87 4.364.87 4.638.93 4.679.93 4.496.9 4.06.82 3.47.69 2.599.52 1.532.31.274.03z"
      />
    </g>
  </svg>
);

const IntermediateDifficulty = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height="40"
    width="45"
    viewBox="0 0 75 40"
    className="difficulty"
    data-testid="svg-intermediate-difficulty"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFF"
        d="M23 38v-.528c-.145-8.568 6.57-15.633 15-15.783 8.43.15 15.145 7.215 15 15.783V38h13v-.528C66 21.212 53.46 8 38 8S10 21.211 10 37.472V38h13z"
      />
      <path
        fill="#F26422"
        d="M37.75 20.76V7.31c-15.46 0-29 12.49-29 28.5 0 .17 1 .82 1 1h13v-.52c-.145-8.43 6.57-15.382 15-15.53z"
      />
      <path
        fill="#333"
        d="M37.75 7.81c15.464 0 28 12.536 28 28H53.5c0-8.698-7.052-15.75-15.75-15.75S22 27.112 22 35.81H9.75c0-15.464 12.536-28 28-28zm0-1.75C21.326 6.077 8.017 19.386 8 35.81v1.75h15.75v-1.75c0-7.732 6.268-14 14-14s14 6.268 14 14v1.75H67.5v-1.75C67.483 19.386 54.174 6.077 37.75 6.06z"
      />
      <path
        fill="#333"
        d="M36 37.81h3.52L37.76.42V.16c0-.08-.06-.21-.09 0-.01.09-.01.18 0 .27v.79l-.08 2-.21 4.3-.19 4.38-.2 4.66-.21 4.7-.2 4.52-.18 4.11-.16 3.48-.17 2.63-.07 1.5v.31z"
      />
    </g>
  </svg>
);

const AdvancedDifficulty = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="45"
    height="40"
    viewBox="0 0 75 40"
    className="difficulty"
    data-testid="svg-advanced-difficulty"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#FFF"
        d="M52.73 36.62v-.51a15.26 15.26 0 0 0-15.13-15.4c-8.419.229-15.063 7.23-14.85 15.65v.51L9.77 37v-.51c-.14-16 12.29-29.09 27.75-29.22C52.98 7.14 65.62 20 65.75 36v.52l-13.02.1z"
      />
      <path
        fill="#F26422"
        d="M22.9 36.71v-.51c-.063-7.86 5.859-14.48 13.677-15.291 7.82-.81 14.973 4.455 16.523 12.161.2 1 13.54-2.36 13.16-1.61C64.098 17.576 52.19 7.307 38.14 7.21a28.75 28.75 0 0 0-28.5 29v.51l13.26-.01z"
      />
      <path
        fill="#333"
        d="M37.75 7.75c15.375 0 27.874 12.396 28 27.77l-12.25.1C53.429 26.972 46.398 20 37.75 20h-.13A15.75 15.75 0 0 0 22 35.88L9.75 36A28 28 0 0 1 37.52 7.75h.23zm0-1.75h-.25C21.072 6.143 7.867 19.572 8 36v1.75h1.75L22 37.63h1.75v-1.75a14 14 0 0 1 13.88-14.13h.12c7.685 0 13.934 6.195 14 13.88v1.75h1.75l12.25-.1h1.75V35.5C67.32 19.185 54.065 6.043 37.75 6z"
      />
      <path
        fill="#333"
        d="M37.77 37.75l-.58-3.48 37.17-4.39h.26c.2 0 .21 0 0 .08a2.44 2.44 0 0 1-.26.06l-.78.16-2 .42c-1.4.29-2.8.6-4.2.9l-4.3.91-4.56 1-4.6 1-4.42.93-4 .86-3.41.72-2.56.54-1.5.32-.26-.03z"
      />
    </g>
  </svg>
);

const UnderTwoHours = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="40"
    style={{ paddingRight: '5px' }}
    viewBox="0 0 35 40"
  >
    <g fill="none" fillRule="nonzero" stroke="#333" transform="translate(0 3)">
      <circle cx="17.5" cy="17.5" r="16.739" fill="#FFF" />
      <path fill="#F26422" d="M17.5 17.5V.76a16.74 16.74 0 0 1 7.791 1.918L17.5 17.5z" />
    </g>
  </svg>
);

const TwoToFourHours = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="40"
    style={{ paddingRight: '5px' }}
    viewBox="0 0 35 40"
  >
    <g fill="none" fillRule="nonzero" stroke="#333" transform="translate(0 3)">
      <circle cx="17.5" cy="17.5" r="16.739" fill="#FFF" />
      <path fill="#F26422" d="M17.5 17.5V.76a16.74 16.74 0 0 1 7.791 1.918L17.5 17.5z" />
    </g>
  </svg>
);

const OverOneDay = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="40"
    style={{ paddingRight: '5px' }}
    viewBox="0 0 35 40"
  >
    <g fill="none" fillRule="nonzero">
      <path
        fill="#333"
        d="M9.863 6.91V5.257a1.002 1.002 0 0 1 .486-.97 1.082 1.082 0 0 1 1.115 0c.341.207.53.584.486.97v1.605h12.188V5.86v-.676a.974.974 0 0 1 .309-.696c.194-.183.456-.284.728-.281.286-.017.566.083.77.276a.96.96 0 0 1 .305.738V6.91h4.888a3.72 3.72 0 0 1 2.682 1.031 3.46 3.46 0 0 1 1.068 2.59v23.185a3.472 3.472 0 0 1-1.095 2.676A3.738 3.738 0 0 1 31 37.397H5.075a3.71 3.71 0 0 1-2.765-1.024 3.446 3.446 0 0 1-1.06-2.67V10.688a3.48 3.48 0 0 1 1.072-2.751A3.75 3.75 0 0 1 5.175 6.91h4.688zm22.887 9.233H3.337v17.464c-.083.49.084.99.448 1.342.364.352.882.512 1.39.432h25.738c.508.08 1.025-.08 1.39-.432.364-.352.53-.851.447-1.342V16.143zm0-2.052v-.241-3.391a1.483 1.483 0 0 0-.479-1.114 1.595 1.595 0 0 0-1.171-.42h-4.563c-.1.026-.196.063-.287.11v1.701c0 .688.087 1.352-1.25 1.352-.613 0-.8-.652-.8-1.328V9.384 8.95H12a.338.338 0 0 0 0 .109v1.75c0 .64-.213 1.279-.813 1.279-1.374 0-1.25-.652-1.25-1.207V8.938H5.075a1.633 1.633 0 0 0-1.286.454c-.336.33-.502.787-.452 1.248v3.451H32.75z"
      />
      <path
        fill="#FFF"
        d="M32.75 16.143v17.464c.083.49-.083.99-.448 1.342a1.636 1.636 0 0 1-1.39.432H5.176a1.636 1.636 0 0 1-1.39-.432 1.512 1.512 0 0 1-.448-1.342V16.143H32.75zM32.725 14.091H3.337V10.64c-.054-.463.11-.924.447-1.257.337-.332.811-.5 1.291-.457h4.788v2.003c-.044.387.144.763.486.97.34.206.774.206 1.115 0 .341-.207.53-.583.486-.97V9.071a.338.338 0 0 1 0-.109h12.113v1.919c-.052.299.035.605.237.837.202.232.499.367.813.37.31-.01.6-.148.799-.379.198-.23.285-.532.238-.828V9.034c.1-.01.2-.01.3 0h4.563c.433-.023.858.129 1.17.42.314.29.487.694.48 1.113v3.392c.087-.097.075.024.062.132z"
      />
      <path
        fill="#CCC"
        d="M30.95 18.497a.485.485 0 0 1 .375.132.45.45 0 0 1 .137.362v1.931a.45.45 0 0 1-.145.373.486.486 0 0 1-.392.122h-20.5a.485.485 0 0 1-.389-.131.45.45 0 0 1-.136-.376v-1.907c0-.337.175-.494.55-.506h20.5zM30.95 23.734a.485.485 0 0 1 .375.133.45.45 0 0 1 .137.362v1.931a.45.45 0 0 1-.145.373.486.486 0 0 1-.392.122h-10.5a.485.485 0 0 1-.389-.131.45.45 0 0 1-.136-.376v-1.895c0-.35.175-.506.55-.519h10.5z"
      />
      <path
        fill="#F26722"
        d="M18.45 22.914c.14-.016.28.031.38.128.1.096.149.231.133.367v3.415a.45.45 0 0 1-.14.38.486.486 0 0 1-.398.127h-3.75a.485.485 0 0 1-.389-.131.45.45 0 0 1-.136-.376v-3.403c0-.35.175-.507.55-.507h3.75z"
      />
      <path
        fill="#CCC"
        d="M12.425 23.734a.485.485 0 0 1 .375.133.45.45 0 0 1 .137.362v1.931a.45.45 0 0 1-.145.373.486.486 0 0 1-.392.122H5.862a.485.485 0 0 1-.388-.131.45.45 0 0 1-.136-.376v-1.895c0-.35.175-.506.55-.519h6.537zM31.462 29.31a.45.45 0 0 0-.137-.362.485.485 0 0 0-.375-.132H5.887c-.374 0-.537.168-.55.518v1.895a.45.45 0 0 0 .137.376.485.485 0 0 0 .388.131h25a.486.486 0 0 0 .393-.122.45.45 0 0 0 .145-.373c.075-.64.075-1.291.063-1.93z"
      />
    </g>
  </svg>
);

const HasVideo = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
    <g fill="none" fillRule="evenodd">
      <path fill="#F96302" d="M0 0h22v22H0z" />
      <path
        fill="#FFF"
        d="M7.959 14.673c0 .353.381.572.686.397l3.181-1.837 3.181-1.837a.457.457 0 0 0 0-.792l-3.18-1.837-3.182-1.836a.457.457 0 0 0-.686.396v7.346zM11 3a8 8 0 1 0 0 16 8 8 0 0 0 0-16zm0 .996A7.012 7.012 0 0 1 18.004 11 7.012 7.012 0 0 1 11 18.004 7.012 7.012 0 0 1 3.996 11 7.012 7.012 0 0 1 11 3.996z"
      />
    </g>
  </svg>
);

function SVG({ svgName }) {
  const svgs = {
    BeginnerDifficulty,
    IntermediateDifficulty,
    AdvancedDifficulty,
    UnderTwoHours,
    TwoToFourHours,
    OverOneDay,
    HasVideo,
  };

  const SVGComponent = svgs[svgName];

  return <SVGComponent />;
}

SVG.propTypes = {
  svgName: string.isRequired,
};

export { SVG };
