import React from 'react';
import { string, number, shape, arrayOf } from 'prop-types';
import {
  params,
  shape as shapeType,
  arrayOf as arrayType,
  string as stringType,
  number as numberType,
  bool as boolType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { ArticleTileForAlp } from '@thd-olt-component-react/article-tile';
import { Row } from '@thd-olt-component-react/core-ui';
import './article-results.scss';

const ArticleTilesContainer = ({ variables }) => {

  const { data, loading, error } = useDataModel('articles', {
    variables,
  });

  if (!data || loading || error) {
    return null;
  }

  const summaries = data?.articles?.summaries || [];

  const tiles = summaries.map((tile) => ({
    ...tile,
    canonicalURL: tile?.url,
    imageURL: tile?.thumbnailImage,
    hasVideo: !!tile?.hasVideoPreview,
    articleType: tile?.type?.toUpperCase(),
  }));

  return (
    <Row data-component="ArticleTilesContainer">
      <div data-testid="article-tiles-container" className="article-results">
        <div className="article-results__tiles">
          {tiles.map((tile, i) => (
            <ArticleTileForAlp key={`tile-${i}`} tile={tile} />
          ))}
        </div>
      </div>
    </Row>
  );
};

ArticleTilesContainer.propTypes = {
  variables: shape({
    categoryID: string.isRequired,
    filters: arrayOf(string),
    limit: number,
    page: number,
    topicID: string,
    type: string
  }).isRequired
};

ArticleTilesContainer.displayName = 'ArticleTilesContainer';

ArticleTilesContainer.dataModel = {
  articles: params({
    categoryID: stringType().isRequired(),
    topicID: stringType(),
    page: numberType(),
    limit: numberType(),
    type: stringType(),
    filters: arrayType(stringType()),
  }).shape({
    summaries: arrayType(
      shapeType({
        url: stringType(),
        tagline: stringType(),
        thumbnailImage: stringType(),
        type: stringType(),
        title: stringType(),
        difficulty: shapeType({
          display: stringType(),
        }),
        duration: shapeType({
          display: stringType(),
        }),
        hasVideoPreview: boolType(),
      })
    ),
  }),
};

export { ArticleTilesContainer };
