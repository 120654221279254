import { Tag } from './Tag';

class RedirectTag extends Tag {
  constructor(attributes, bypass = false, history) {
    super();
    this.type = 'redirect';
    this.prefix = 'thd-helmet__redirect';
    this.attributes = attributes;
    this.attributes.id = `${this.prefix}`;
    this.bypass = bypass;
    this.history = history;
    this.hasClientSideRedirect = true;
  }

  // eslint-disable-next-line class-methods-use-this
  updateDom() {
    console.warn('server side redirect found:', this.attributes);
    if (!this.hasClientSideRedirect) {
      if (this.history && !this.bypass) {
        this.hasClientSideRedirect = true;
        this.history.replace(this.attributes.redirectPath);
      } else if (!this.bypass) {
        this.hasClientSideRedirect = true;
        window.location.replace(this.attributes.redirectPath);
      }
    }
  }

  // eslint-disable-next-line class-methods-use-this
  removeFromDom() {}

  toString() {
    // if 404 throw
    const {
      httpCode,
      error,
      isErrorStatus,
      shouldRedirect,
      redirectPath
    } = this.attributes;

    if (isErrorStatus) {
      if (httpCode) {
        let errorObj = '';
        if (error) {
          try {
            errorObj = JSON.stringify(error);
            // eslint-disable-next-line no-empty
          } catch (parsingError) {
          }
        }

        if (this.bypass) {
          console.log(`bypassing redirect error: ${httpCode}`);
          if (errorObj) {
            console.log(`error: ${errorObj}`);
          }

          return null;
        }

        // eslint-disable-next-line max-len
        const redirectError = new Error(`Redirector Error. Redirector returned an error code: ${httpCode}\n${errorObj}`);
        redirectError.responseStatus = httpCode;
        throw redirectError;
      }
    }

    if (shouldRedirect) {
      if (this.bypass) {
        console.log(`bypassing redirect -  [(${httpCode}):${redirectPath}]`);
        return null;
      }
      if (!redirectPath || redirectPath === 'undefined') {
        console.log(`Invalid redirect path: ${redirectPath}`);
        return null;
      }
      const redirectError = new Error(`Redirector: redirect found - ${httpCode}:${redirectPath}`);
      redirectError.responseStatus = httpCode;
      redirectError.redirectPath = redirectPath;
      throw redirectError;
    }

    return null;
  }
}

export { RedirectTag };
