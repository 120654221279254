/* eslint-disable */
import React from "react";
import "./article-tile.scss";
import { Col } from "@thd-olt-component-react/grid";
import { object } from "prop-types";
import { SVG } from "./subcomponents/SVGs";
import { ProjectGuide } from "./subcomponents/ProjectGuide";

const ArticleTileForAlp = ({ tile }) => {
  const {
    canonicalURL,
    imageURL,
    articleType = "AP",
    title,
    difficulty,
    duration,
    hasVideo,
    tagline
  } = tile;

  const articleTypeNames = {
    AB: "Buying Guide",
    AI: "Inspiration Guide",
    AH: "Project Guide",
    AP: "Influencer Guide",
  };

  const isProjectGuide = articleTypeNames[articleType] === "Project Guide";

  return (
    <Col xs="6" sm="4" md="4" lg="4" data-component="ArticleTileForAlp">
      <div className="article-tile__article-list-page__article-listing__items__item--alp">
        <a
          href={canonicalURL}
          className="article-tile__article-list-page__header__quick-guide-links-container__quick-guide-card-link"
        >
          <div
            className="article-tile__article-list-page__article-listing__items__item__header--alp"
            style={{ lineHeight: "100px" }}
          >
            <img
              src={imageURL}
              alt={title}
              className="article-tile__article-list-page__article-listing__items__item__header--alp__img stretchy"
            />
            {hasVideo && (
              <div className="article-tile__article-list-page__article-listing__items__item__header__videoR">
                <SVG svgName="HasVideo" />
              </div>
            )}
          </div>
        </a>
        <div className="article-tile__article-list-page__article-listing__items__item__content">
          <a
            href={canonicalURL}
            className="article-tile__article-list-page__header__quick-guide-links-container__quick-guide-card-link"
          >
                <span className="u__bold article-tile__article-list-page__article-listing__items__item__content__type">
                {articleTypeNames[articleType]}
              </span>
            <h2 className="article-tile__article-list-page__article-listing__items__item__content__title">
              {title}
            </h2>
            <hr className="article-tile__article-list-page__article-listing__items__item__content__hr" />
            {isProjectGuide && <ProjectGuide difficulty={difficulty?.display} duration={duration?.display} />}
          </a>
          <h3 className="article-tile__article-list-page__article-listing__items__item__content-requirements-intro-projectGuide">
            {tagline}
          </h3>
        </div>
      </div>
    </Col>
  );
};

ArticleTileForAlp.propTypes = {
  tile: object.isRequired,
};

ArticleTileForAlp.displayName = "ArticleTile";

export { ArticleTileForAlp };
