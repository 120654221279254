import React, { useState } from 'react';
import {
  shape, func, arrayOf, string, bool, number
} from 'prop-types';
import cx from 'classnames';
import { filterHeaderMapping } from './constants';
import { QuickFilterButton } from './QuickFilterButton';
import { FilterGroup } from './FilterGroup';
import { FilterPills } from './FilterPills';
import styles from './article-listing-filters.module.scss';

const MobileFilters = ({
  filterGroups = {},
  checkedFilters,
  toggleChecked,
  clearAllFilters,
  loading,
  totalArticles
}) => {
  const { informationTypeCounts: articleTypes } = filterGroups;
  const selectedType = articleTypes?.find(({ id }) => id === checkedFilters[0])?.title || 'Information Types';
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const filterGroupKeys = Object.keys(filterGroups);

  if (!filterGroupKeys?.length) return null;

  const toggleFilters = () => setSidebarOpen(!isSidebarOpen);
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen);

  const allFilters = filterGroupKeys.map((key, index) => {
    return (
      <FilterGroup
        loading={loading}
        className={cx(styles['sidebar-filter-group'])}
        key={index}
        checkedFilters={checkedFilters}
        toggleChecked={toggleChecked}
        header={filterHeaderMapping[key]}
        filters={filterGroups[key]}
        showFilters={false}
      />
    );
  });
  const dropdownClasses = cx({
    [styles.hide]: !isDropdownOpen,
    [styles.expanded]: isDropdownOpen
  });

  const sidebarClasses = cx(styles.sidebar, { [styles.hide]: !isSidebarOpen });

  return (
    <>
      <div
        className={cx(styles['type-filters'], { [styles.expanded]: isDropdownOpen })}
        data-component="ArticleListingMobileFilters"
      >
        <button type="button" className={styles['filters-btn']} onClick={toggleFilters}>Filters</button>
        <QuickFilterButton
          onClick={toggleDropdown}
          selectedType={selectedType}
          className={styles['info-type']}
        />
      </div>
      <ul className={dropdownClasses} data-testid="mobile" data-component="ArticleListingFilterGroup">
        <FilterGroup
          loading={loading}
          className={styles['mobile-filter-group']}
          checkboxClassName={styles['mobile-checkbox']}
          key="filterGroup"
          checkedFilters={checkedFilters}
          toggleChecked={toggleChecked}
          filters={articleTypes}
        />
      </ul>
      <FilterPills
        filterGroups={filterGroups}
        toggleChecked={toggleChecked}
        clearAllFilters={clearAllFilters}
        totalArticles={totalArticles}
        checkedFilters={checkedFilters}
      />
      <div className={sidebarClasses} data-component="ArticleListingSidebar">
        <div className={styles['close-btn']}>
          <span>Filter</span>
          <button type="button" onClick={toggleFilters}>
            <img
              src="https://assets.homedepot-static.com/images/v1/close-symbol-orange.svg"
              alt="Close"
              width={25}
              height={25}
            />
          </button>
        </div>
        <div className={styles['modal-clear-all']}>
          <span>Filters</span>
          <button type="button" onClick={clearAllFilters}>Clear All</button>
        </div>
        {allFilters}
        <div className={styles['view-results']}>
          <button type="button" onClick={toggleFilters}>View Results</button>
        </div>
      </div>
    </>
  );
};

MobileFilters.propTypes = {
  filterGroups: shape({}).isRequired,
  checkedFilters: arrayOf(string).isRequired,
  toggleChecked: func.isRequired,
  clearAllFilters: func.isRequired,
  loading: bool,
  totalArticles: number
};

MobileFilters.defaultProps = {
  loading: false,
  totalArticles: 0
};

export { MobileFilters };