import React from 'react';
import { string, func } from 'prop-types';
import styles from './article-listing-filters.module.scss';

const Pill = ({ id, label, title, toggleChecked }) => (
  <button className={styles.pill} type="button" onClick={() => toggleChecked(id)}>
    <span className={styles['pill-text']}>{`${label}: ${title}`}</span>
  </button>
);

Pill.propTypes = {
  id: string.isRequired,
  label: string.isRequired,
  title: string.isRequired,
  toggleChecked: func.isRequired
};

export { Pill };
