import React from 'react';
import { string } from 'prop-types';
import { SVG } from './SVGs';

/* eslint-disable max-len */
const TimeNeeded = ({ timeNeeded }) => {
  return (
    <div className="col__6-12--xs col__12-12 article-tile__article-list-page__article-listing__items__item__content-requirements__content__duration">
      {timeNeeded === 'Under 2 hours' && <SVG svgName="UnderTwoHours" />}
      {timeNeeded === '2-4 hours' && <SVG svgName="TwoToFourHours" />}
      {timeNeeded === 'Over 1 day' && <SVG svgName="OverOneDay" />}
      <span className="u__bold article-tile__article-list-page__article-listing__items__item__content-requirements__content__title">
        Time
      </span>
      <span className="article-tile__article-list-page__article-listing__items__item__content-requirements__content__text">
        {timeNeeded}
      </span>
    </div>
  );
};

TimeNeeded.propTypes = {
  timeNeeded: string.isRequired,
};

export { TimeNeeded };
