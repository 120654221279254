import React, { useContext, useEffect } from 'react';
import classNames from 'classnames/bind';
import { string } from 'prop-types';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Carousel } from '@thd-olt-component-react/carousel';
import {
  params, shape, string as stringType, number as numberType, bool as boolType, useDataModel, arrayOf
} from '@thd-nucleus/data-sources';
import styles from './article-listing-topics.module.scss';
import { MobileTopics } from './subcomponent/MobileTopics';

const ArticleListingTopics = ({ categoryID }) => {
  useEffect(() => { LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('article-listing-topics.ready'); }, []);

  const { data, loading, error } = useDataModel('topic', {
    variables: { categoryID },
  });
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  if (!data || loading || error) {
    return (
      <div
        className={styles['article-listing-loading']}
        data-component="ArticleListingTopicsPlaceholder"
      />
    );
  }
  const articleListingTopicsContainer = classNames(
    styles['article-listing-topics']
  );
  const articleListingTopic = classNames(
    styles['article-listing-topics__topic']
  );

  const articleListingLink = classNames(
    styles['article-listing-topics__link']
  );

  const topics = data?.topic;

  let topicsArray = Array.isArray(topics) && topics.length && topics.map((topic, index) => {
    return (
      <div key={index} className={articleListingTopic}>
        <a href={topic.url} className={articleListingLink}>
          {topic.title} ({topic.count})
        </a>
      </div>
    );
  });

  if (isMobile) {
    return (
      <MobileTopics topics={topicsArray} />
    );
  }

  return (
    <div
      data-testid="article-topics"
      className={articleListingTopicsContainer}
      data-component="ArticleListingTopics"
    >
      <Carousel
        itemClass="fixed-item"
        fixedItem
        multiItem={false}
        itemWidthPixelsDesktop={220}
        itemWidthPixelsMobile={220}
        transparent
      >
        {topicsArray}
      </Carousel>
    </div>
  );
};

ArticleListingTopics.propTypes = {
  categoryID: string.isRequired,
};

ArticleListingTopics.displayName = 'ArticleListingTopics';

ArticleListingTopics.dataModel = {
  topic: arrayOf(params({ categoryID: stringType().isRequired() }).shape({
    title: stringType(),
    id: stringType(),
    type: stringType(),
    count: numberType(),
    url: stringType(),
    isCurrentPage: boolType()
  })
  )
};

export { ArticleListingTopics };
