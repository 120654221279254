import React from 'react';
import {
  func, shape, number, string, arrayOf
} from 'prop-types';
import { Pill } from './Pill';
import styles from './article-listing-filters.module.scss';

const groupLabels = {
  informationTypeCounts: 'Information Type',
  brands: 'Brand',
  homeAreas: 'Home Area',
  keywords: 'Keyword',
  seasons: 'Season',
  regions: 'Region',
};

const FilterPills = ({
  filterGroups, toggleChecked, clearAllFilters, totalArticles, checkedFilters
}) => {
  let filterPillsArray = [];
  Object.keys(filterGroups).forEach((key, indexi) => {
    const group = filterGroups[key];

    if (!Array.isArray(group) || !group.length) return;

    const selectedPills = group
      .filter(({ id }) => !!checkedFilters.includes(id))
      .map(({ title, id }, indexj) => (
        <Pill
          key={indexi + '' + indexj}
          id={id}
          label={groupLabels[key]}
          title={title}
          toggleChecked={toggleChecked}
        />
      ));

    filterPillsArray = [...filterPillsArray, ...selectedPills];
  });

  return (
    <div className={styles['filter-pills']} data-component="ArticleListingFilterPills">
      <span className={styles['total-articles']}>{totalArticles} Result{totalArticles !== 1 ? 's' : ''}</span>
      {filterPillsArray}
      {!!filterPillsArray.length
        && <button className={styles['clear-all']} type="button" onClick={clearAllFilters}>Clear All</button>}
    </div>
  );
};

FilterPills.propTypes = {
  filterGroups: shape({}).isRequired,
  toggleChecked: func.isRequired,
  clearAllFilters: func.isRequired,
  checkedFilters: arrayOf(string).isRequired,
  totalArticles: number
};

FilterPills.defaultProps = {
  totalArticles: 0
};

export { FilterPills };