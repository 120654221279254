import React, { useContext, useEffect } from 'react';
import {
  string, func, instanceOf, number
} from 'prop-types';
import {
  params,
  shape,
  arrayOf as arrayType,
  string as stringType,
  useDataModel,
} from '@thd-nucleus/data-sources';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { filterHeaderMapping } from './constants';
import { FilterGroup } from './FilterGroup';
import { MobileFilters } from './MobileFilters';
import { FilterPills } from './FilterPills';
import styles from './article-listing-filters.module.scss';

const ArticleListingFilters = ({
  categoryID, topicID, filters, type, updateVariables, triggerAnalytics, totalArticles
}) => {
  const currentCheckedFilters = [type, ...filters];
  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile';

  const variables = {
    categoryID,
    type: type || undefined,
    topicID: topicID || undefined,
    filters: filters && filters.length > 0 ? filters : undefined
  };

  const { data, loading, error } = useDataModel('filters', {
    variables
  });

  const filterGroups = data?.filters || {};
  const filterGroupKeys = Object.keys(filterGroups);
  useEffect(() => {
    LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('article-listing-filters.ready');
  }, []);

  useEffect(() => {
    const analyticsFilters = {};
    filterGroupKeys.forEach((key) => {
      const group = filterGroups[key];
      if (!Array.isArray(group) || !group.length) return;
      analyticsFilters[key] = group.map((filter) => (
        {
          ...filter,
          isCurrentPage: currentCheckedFilters.includes(filter.id)
        }
      ));
    });
    triggerAnalytics(analyticsFilters);
  }, [filterGroups]);

  if (!data || error || !filterGroupKeys.length) {
    return (
      <>
        <div className={styles['filter-pills']} data-component="ArticleListingFilterPillsPlaceholder" />
        <div className={styles.placeholder} data-component="ArticleListingFiltersPlaceholder" />
      </>
    );
  }

  const toggleChecked = (id) => {
    if (id?.length === 2) {
      const typeValue = id === type ? undefined : id;
      updateVariables([typeValue, ...filters]);
    } else {
      const newFilters = [...filters];
      const index = newFilters.indexOf(id);
      if (index > -1) newFilters.splice(index, 1);
      else newFilters.push(id);
      updateVariables([type, ...newFilters]);
    }
  };

  const clearAllFilters = () => updateVariables([]);

  if (isMobile) {
    return (
      <MobileFilters
        totalArticles={totalArticles}
        loading={loading}
        filterGroups={filterGroups}
        toggleChecked={toggleChecked}
        checkedFilters={currentCheckedFilters}
        clearAllFilters={clearAllFilters}
      />
    );
  }

  const renderedFilters = filterGroupKeys.map((key, index) => (
    <FilterGroup
      loading={loading}
      data-testid="filter-group"
      key={index}
      checkedFilters={currentCheckedFilters}
      toggleChecked={toggleChecked}
      header={filterHeaderMapping[key]}
      filters={filterGroups[key] || []}
      isMobile={false}
    />
  ));

  return (
    <>
      <FilterPills
        checkedFilters={currentCheckedFilters}
        clearAllFilters={clearAllFilters}
        totalArticles={totalArticles}
        filterGroups={filterGroups}
        toggleChecked={toggleChecked}
      />
      <div className={styles['article-listing-filters']} data-component="ArticleListingFilters">
        {renderedFilters}
      </div>
    </>
  );
};

ArticleListingFilters.propTypes = {
  categoryID: string,
  topicID: string,
  updateVariables: func.isRequired,
  triggerAnalytics: func.isRequired,
  totalArticles: number,
  type: string,
  filters: instanceOf(Array)
};

ArticleListingFilters.defaultProps = {
  categoryID: '',
  topicID: '',
  filters: [],
  type: '',
  totalArticles: 0
};

ArticleListingFilters.displayName = 'ArticleListingFilters';

ArticleListingFilters.dataModel = {
  filters: params({
    categoryID: stringType(),
    topicID: stringType(),
    filters: arrayType(stringType()),
    type: stringType()
  }).shape({
    informationTypeCounts: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    brands: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    homeAreas: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    keywords: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    seasons: arrayType(shape({
      id: stringType(),
      title: stringType()
    })),
    regions: arrayType(shape({
      id: stringType(),
      title: stringType()
    }))
  })
};

export { ArticleListingFilters };
