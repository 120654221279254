import React, { useState } from 'react';
import { useParams, useLocation } from '@thd-olt-component-react/router';
import { Row, Col } from '@thd-olt-component-react/grid';
import { ALPRedirector } from '@thd-nucleus/app-render';
import {
  ArticleTilesContainer,
  DiyBanner,
  GetItInstalled,
} from '@thd-olt-component-react/article-results';
import { ExperienceContext, useConfigService } from '@thd-nucleus/experience-context';
import { ArticleListingTopics } from '@thd-olt-component-react/article-listing-topics';
import { ArticleListingFilters } from '@thd-olt-component-react/article-listing-filters';
import { ErrorBoundary } from '@thd-olt-component-react/error-boundary';
import { Breadcrumbs } from '@thd-olt-component-react/breadcrumbs';
import { declareContexts } from '@thd-olt-component-react/hydrator';
import { EmtLinks } from '@thd-olt-component-react/emt-links';
import { Pagination } from '@thd-olt-component-react/pagination';
import { Metadata, NoIndexNoFollowMetadata } from '@thd-olt-component-react/metadata';
import { SponsoredNonEndemic } from '@thd-olt-component-react/sponsored-content';
import '../styles/articles-pagination.scss';
import '../styles/articles-listing-page.scss';
import {
  extend,
  params,
  shape,
  arrayOf,
  string as stringType,
  number as numberType,
  useDataModel,
  QueryContext,
} from '@thd-nucleus/data-sources';
import styles from '../styles/alpStyles.module.scss';
import { useALPVariables } from '../helpers/useALPVariables';
import { useWindowEffects } from '../helpers/useWindowEffects';

export const ArticleListingPage = () => {
  declareContexts([ExperienceContext, QueryContext]);

  const { context = '' } = useParams();
  const { pathname = '', search = '' } = useLocation();

  const {
    pageVariables, popState, variables, handlePopState, updateVariables
  } = useALPVariables(pathname);

  const {
    categoryID, topicID, type, filters, page
  } = pageVariables;
  const [mounted, setMounted] = useState(false);

  const { data, loading } = useDataModel('articles', {
    variables: pageVariables,
  });

  const canonicalURL = data?.articles?.canonicalURL || '';
  const hasNCNI5 = canonicalURL.includes('NCNI-5');
  let queryParams = search && search.replace('?NCNI-5', '');
  if (hasNCNI5 && search && search.indexOf('NCNI-5') < 0) {
    queryParams = search.replace('?', '&');
  }
  const contextValue = context ? '/' + context : '';
  const completePath = contextValue + canonicalURL + queryParams;
  const isNonEndemicBannerEnabled = useConfigService('fs-prop:isNonEndemicBannerEnabled') || false;

  useWindowEffects({
    popState,
    handlePopState,
    canonicalURL,
    variables,
    mounted,
    setMounted,
    completePath,
  });

  if (!data) {
    return null;
  }
  const {
    breadcrumbs = [],
    titleText = '',
    totalArticles,
    totalPages,
    articlesPerPage,
  } = data?.articles || {};

  const pageStart = (page - 1) * articlesPerPage + 1;
  const pageEnd = Math.min(page * articlesPerPage, totalArticles);
  const pageRange = `${pageStart}-${pageEnd}`;
  const isDebug = search.includes('debug');
  const justPath = completePath.substring(completePath.indexOf('?'));
  const metadata = { ...data?.articles?.metadata, ogType: 'Article List Page' };

  const queryHandler = (name, pageNum = 1) => {
    const [, , , pageTitle, filtersParam] = canonicalURL.split('/');
    const pageString = pageNum > 1 ? '/' + pageNum : '';
    const value = `${contextValue}/c/alp/${pageTitle}/${filtersParam}${pageString}${search}`;
    return {
      name,
      value,
    };
  };

  const triggerAnalytics = (filterGroups) => {
    window.LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('article-listing-page.ready', {
      breadcrumbs,
      variables: {
        ...pageVariables,
        categoryId: categoryID,
        topicId: topicID,
        infoType: type,
        filterArray: filters,
      },
      filters: filterGroups,
    });
  };

  return (
    <Row className="article-listing-page" name="article-listing-page-wrapper">
      <ErrorBoundary name="redirector" useContextForProps>
        <ALPRedirector variables={pageVariables} path={completePath} />
      </ErrorBoundary>
      <ErrorBoundary name="metadata">
        {canonicalURL.includes('NCNI-5') ? (
          <Metadata data={metadata}>
            <NoIndexNoFollowMetadata />
          </Metadata>
        ) : (
          <Metadata data={metadata} />
        )}
      </ErrorBoundary>
      <Row className="isBound">
        <Col xs="12" sm="12" md="12" lg="12" className="articles-breadcrumbs">
          <ErrorBoundary name="breadcrumbs">
            <Breadcrumbs staticCrumbs={breadcrumbs} />
          </ErrorBoundary>
        </Col>
      </Row>
      {isNonEndemicBannerEnabled
        && (
          <Row className="isBound">
            <Col xs="12" sm="12" md="12" lg="12" className="articles-non-endemic-banner">
              <ErrorBoundary name="articles-non-endemic-banner">
                <SponsoredNonEndemic experienceSlug="alp" />
              </ErrorBoundary>
            </Col>
          </Row>
        )}
      <Row className="isBound">
        <Col xs="12" sm="12" md="12" lg="12" className="articles-title">
          <ErrorBoundary name="articles-title">
            <h1 className="articles-title__heading">{titleText}</h1>
          </ErrorBoundary>
        </Col>
      </Row>
      <Row className="isBound">
        <Col xs="12" sm="12" md="12" lg="12">
          <ErrorBoundary name="article-listing-topics">
            <ArticleListingTopics categoryID={categoryID} />
          </ErrorBoundary>
        </Col>
      </Row>
      <Row className="isBound">
        <Col xs="12" sm="12" md="12" lg="12">
          <div className={styles.filters}>
            <ArticleListingFilters
              categoryID={categoryID}
              topicID={topicID}
              type={type}
              filters={filters}
              totalArticles={totalArticles}
              updateVariables={updateVariables}
              triggerAnalytics={triggerAnalytics}
            />
            <div className={styles['tiles-container']}>
              {totalArticles < 1 ? (
                <div className="alp-no-results">
                  <div className="alp-bold-message">
                    <p><strong>No results matching your filter selections</strong></p>
                  </div>
                  <div className="alp-message">
                    <p>Try changing or removing some filters to find the right guide</p>
                  </div>
                </div>
              ) : null}
              <ArticleTilesContainer variables={pageVariables} />
            </div>
          </div>
        </Col>
      </Row>
      <Row className="isBound">
        <div className={styles.banners}>
          {!loading && (
            <>
              <Pagination
                currentPage={page}
                maxNumericBlocks={5}
                showArrows
                totalPages={totalPages}
                href={canonicalURL}
                className="articles-pagination__pod"
                noQueryParams
                queryHandler={queryHandler}
                nao={false}
                ssr={!isDebug}
                onPageChanged={() => {}}
              />
              <div className="articles-pagination__counts">
                Showing
                <span data-testid="range" className="articles-pagination__counts--number">
                  {' '}
                  {pageRange}{' '}
                </span>
                of
                <span data-testid="total" className="articles-pagination__counts--number">
                  {' '}
                  {totalArticles}{' '}
                </span>
                results.
              </div>
            </>
          )}
          <Col xs="12" sm="6" md="6" lg="6">
            <DiyBanner />
          </Col>
          <Col xs="12" sm="6" md="6" lg="6">
            <GetItInstalled />
          </Col>
        </div>
      </Row>
      <Row className="isBound">
        <EmtLinks path={justPath} />
      </Row>
    </Row>
  );
};

ArticleListingPage.displayName = 'ArticleListingPage';

ArticleListingPage.dataModel = extend(
  {
    articles: params({
      categoryID: stringType().isRequired(),
      topicID: stringType(),
      page: numberType(),
      limit: numberType(),
      type: stringType(),
      filters: arrayOf(stringType()),
    }).shape({
      totalPages: numberType(),
      articlesPerPage: numberType(),
      page: numberType(),
      titleText: stringType(),
      totalArticles: numberType(),
      canonicalURL: stringType(),
      metadata: shape({
        description: stringType(),
        siteName: stringType(),
        title: stringType(),
        type: stringType(),
        canonicalURL: stringType(),
        facebookAdmins: stringType(),
      }),
      breadcrumbs: arrayOf(
        shape({
          dimensionName: stringType(),
          url: stringType(),
          label: stringType(),
        })
      ),
    }),
  },
  ALPRedirector,
  Breadcrumbs,
  ArticleListingTopics,
  ArticleListingFilters,
  ArticleTilesContainer,
  EmtLinks
);
