import React from 'react';
import { string } from 'prop-types';
import { SVG } from './SVGs';

/* eslint-disable max-len */
function Difficulty({ difficulty }) {
  return (
    <div className="col__6-12--xs col__12-12 article-tile__article-list-page__article-listing__items__item__content-requirements__content__difficulty">
      {difficulty === 'Beginner' && <SVG svgName="BeginnerDifficulty" />}
      {difficulty === 'Intermediate' && <SVG svgName="IntermediateDifficulty" />}
      {difficulty === 'Advanced' && <SVG svgName="AdvancedDifficulty" />}
      <span className="u__bold article-tile__article-list-page__article-listing__items__item__content-requirements__content__title">
        Difficulty
      </span>
      <span data-testid="difficulty-text" className="article-tile__article-list-page__article-listing__items__item__content-requirements__content__text">
        {difficulty}
      </span>
    </div>
  );
}

Difficulty.propTypes = {
  difficulty: string.isRequired
};

export { Difficulty };
